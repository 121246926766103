<style scoped lang="scss">
//pc端样式
@media screen and (min-width: 769px) {
  .head-right {
    :deep(.el-input__inner) {
      color: #fff !important;
    }
  }

  .list-word {
    // width: 100%;
    // display: flex;
    // flex-wrap: wrap;
    grid-template-columns: repeat(auto-fill, minmax(220px, 2fr));
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 25px;
    padding: 0 10rem;
    padding-top: 20px;
    background: linear-gradient(180deg, lightblue, #fff, #fff);
  }

  .p1 {
    cursor: not-allowed;
    background-color: #b6b6b6;
    pointer-events: none;
  }
  .life {
    height: 25px;
    width: 75px;
    overflow: hidden;
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    border: 2px solid white;
    border-radius: 50px;
    text-align: center;
    line-height: 25px;
    color: white;
  }

  .life-online {
    background-color: rgb(115, 201, 64);
  }

  .life-busy {
    background-color: red;
  }

  .life-live {
    background-color: #46a6ff;
  }

  .line-offline {
    background-color: #b6b6b6;
  }

  .showMoreButtonCont {
    text-align-last: center;
    margin-top: 20px;
  }

  .button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 18px;
    border-radius: 4px;
    background-color: rgb(189, 219, 228);
    border: 1px solid #ffff;
    cursor: pointer;
    margin: 0 5px;
    box-shadow: rgb(90, 181, 209) 0px -3px 0px inset;
  }
}

@media screen and (max-width: 768px) {
  .head-right {
    display: none !important;
  }
  .moblie {
    display: none;
  }
  .have-List {
    padding: 10px !important;
  }
  #moblieList {
    overflow: hidden;
    display: grid;
    justify-content: flex-start;
  }

  .list-word {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }

  .showMoreButtonCont {
    text-align-last: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .button {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 18px;
    border-radius: 4px;
    background-color: rgb(189, 219, 228);
    border: 1px solid #ffff;
    cursor: pointer;
  }
}
</style>
<template>
  <div>
    <div>
      <navbar ref="navbarRef" />
    </div>
    <div
      class="search"
      style="margin-top: -5px"
      v-if="innerWidthSearch ? store1.serachShow : false"
    >
      <el-input
        v-model="data.searchValue"
        class="search-input"
        placeholder="Search for Expert or category"
        :suffix-icon="Search"
        @change="getSearch(data.searchValue)"
      />
    </div>
    <div class="middle">
      <div class="middle-head">
        <div class="head-left moblie">
          <span class="left" @click="goPage">Home</span>
          <span><svg-icon icon="arrow-right"></svg-icon></span>
          <span class="left-frite">Favorites</span>
        </div>
        <!-- <div class="head-right">
          <el-input
            v-model="searchVal"
            placeholder="Please input"
            class="input-with-select"
          >
            <template #append>
              <el-button :icon="Search" @click="gotoIndexSearch" />
            </template>
          </el-input>
        </div> -->
      </div>
      <div class="content">
        <!-- <div class="have-List">
          <div class="list-word">
            <cardBody
              :cardObject="item"
              v-for="(item, index) in favoriteList"
              :key="index"
            ></cardBody>
          </div>
        </div> -->

        <div class="list-word" v-if="favoriteList.length == 0 && loading">
          <div v-for="(item, index) in 4" :key="index">
            <el-skeleton
              :style="{ width: innerWidthSearch ? '410px' : '210px' }"
              animated
            >
              <template #template>
                <el-skeleton-item
                  variant="image"
                  :style="{
                    width: innerWidthSearch ? '410px' : '210px',
                    height: '210px',
                  }"
                />
                <div style="padding: 14px">
                  <el-skeleton-item variant="p" style="width: 50%" />
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      justify-items: space-between;
                    "
                  >
                    <el-skeleton-item variant="text" style="margin-right: 16px" />
                    <el-skeleton-item variant="text" style="width: 30%" />
                  </div>
                </div>
              </template>
            </el-skeleton>
          </div>
        </div>

        <ul
          class="list-word"
          id="moblieList"
          v-if="favoriteList != 0"
          @scroll="scrolling"
        >
          <cardBody
            :cardObject="item"
            v-for="(item, index) in favoriteList"
            :key="index"
          ></cardBody>
        </ul>
        <div class="noList" v-if="favoriteList.length == 0">
          <h3>No Favorites Yet</h3>
          <p><span @click="goPage">show more Experts</span></p>
        </div>
        <!-- <div v-if="favoriteList.length != 0">
          <a class="show-more" @click="showMore">{{ showmorecontent }}</a>
        </div> -->

        <div
          v-if="showmorecontent == 'Show More'"
          class="showMoreButtonCont"
        >
          <b-button class="button" @click="showMore">{{ showmorecontent }}</b-button>
        </div>
        <!-- <div
          v-else
          style="color: rgb(182, 182, 182); text-align-last: center; margin-top: 20px"
        >
          <a @click="showMore">{{ showmorecontent }}</a>
        </div> -->
      </div>

      <div v-if="store1.isSide">
        <Sidebar />
      </div>
    </div>
    <div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script setup>
import Sidebar from "@/layout/components/Sidebar/index.vue";
import cardBody from "@/components/cardBody";
import { searchList } from "@/api/center.js";
import { phoneStore } from "../../store/phone1";
import Navbar from "../.././layout/components/Navbar.vue";
import Footer from "../.././layout/components/Footer.vue";
import { getCurrentInstance, onMounted, ref, reactive, nextTick } from "vue";
import { ElMessage, ElNotification } from "element-plus";
import { Search } from "@element-plus/icons-vue";
import { setToken, getToken, removeToken, getType } from "@/utils/auth";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import request from "@/utils/request.js";
import bus from "vue3-eventbus";
const innerWidthSearch = ref(false);
const { $t } = getCurrentInstance().proxy;
const store1 = phoneStore();
const router = useRouter();
const store = useStore();
const ImageUrl = process.env.VUE_APP_BASE_ImageUrl;
const showmorecontent = ref("Show More");
const favoriteList = ref([]);
const searchVal = ref("");
const loading = ref(true);
const props = defineProps({
  cardObject: {
    type: Object,
    default: () => {
      return {};
    },
  },
});
// const ImageUrl = process.env.VUE_APP_BASE_ImageUrl

const navbarRef = ref(null);
const route = useRoute();

const data = reactive({
  searchValue: "", //搜索框绑定的值
  searchList: [],
});

const getSearch = (q) => {
  if (q === "") {
    data.isHideSwiper = false;
    return;
  }
  searchList({ searchKeys: q }).then((res) => {
    if (res.code == 200) {
      data.isHideSwiper = true;
      data.searchList = res.rows;
      router.push("/onlineExpert?q=" + data.searchValue);
    }
  });
  store1.serachShow = false;
};

onMounted(() => {
  store1.isSide = false;
  store1.serachShow = false;
  // store1.isSide = true;
  // console.log(store1.isSide,'store1.isSide')

  if (route.query.q) {
    getSearch(route.query.q);
  } else {
    navbarRef.value.aixinFlag = true;
    getFavorite();
  }

  if (window.innerWidth < "768") {
    innerWidthSearch.value = true;
  }
});

const goPage = () => {
  router.push("/onlineExpert");
};
const pageNum = ref(1);
const pageSize = ref(16);

async function getFavorite() {
  let params = {
    pageNum: pageNum.value,
    pageSize: pageSize.value,
  };
  const { code, rows, total } = await request.get("/system/follower/list", {
    params: params,
  });
  rows.forEach((item) => {
    item.collect = "1";
  });
  console.log("rows", rows);
  if (code == 200) {
    loading.value = false;
  }
  if (code == 200 && pageNum.value <= Math.ceil(total / pageSize.value)) {
    favoriteList.value = favoriteList.value.concat(rows);
  }
  if (pageNum.value >= Math.ceil(total / pageSize.value)) {
    showmorecontent.value = "no more";
  }
  // if (code == 200) {
  //   favoriteList.value = rows
  // }
}

// 点击进入详情页面
const goDetailPage = (id, item) => {
  router.push("/detailPage/" + id);
};
function gotoIndexSearch() {
  router.push("/onlineExpert?q=" + searchVal.value);
}
// 展示更多
function showMore() {
  if (showmorecontent.value == "no more") {
    ElMessage("no more.");
    return;
  }
  pageNum.value += 1;
  getFavorite();
}

function openShowMessage(item) {
  bus.emit("chatting", true);
  bus.emit("chattingObj", item.nickname);
}

// 添加到收藏
async function changeFollow(item, index) {
  const userInfo = JSON.parse(store.state.userInfo);
  let data = {
    userId: userInfo.id,
    consultantId: item.id,
  };
  const { code } = await request.post("/system/follower/add", data);
  // , duration: 0
  if (code == 200) {
    item.collect = "1";
    ElNotification({
      dangerouslyUseHTMLString: true,
      message: `<div>Added to favorites!</div>`,
      position: "bottom-right",
    });
  }
}
// 删除收藏
async function changeFollowDel(item, index) {
  const userInfo = JSON.parse(store.state.userInfo);
  let data = {
    userId: userInfo.id,
    consultantId: item.id,
  };
  const { code } = await request.post("/system/follower/cancel", data);
  if (code == 200) {
    item.collect = "0";
    ElNotification({
      dangerouslyUseHTMLString: true,
      message: `<div>Removed from favorites!</div>`,
      position: "bottom-right",
    });
  }
}
//打电话
const callPhone = (item) => {
  if (getType() == "1") {
    console.log(111);
    bus.emit("chatting", true);
    return;
  }
  console.log(item);
  const { id, listImage, nickname, liveRoom, freeMinutes, perMinute } = item;
  let obj = {
    id,
    listImage,
    nickname,
    liveRoom,
    freeMinutes,
    perMinute,
  };
  console.log(obj);
  store1.isPhoneShow = true;
  store1.CounselorMessage = obj;
  console.log(store1.isPhoneShow, store1.CounselorMessage);
};
</script>

<style lang="scss" scoped>
.filtergly {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray !important;
}

.middle {
  width: 100%;
  // background: #10525e;
  padding-bottom: 20px;

  .middle-head {
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ffff;
    border-color: rgba(251, 249, 249, 0.1);
    font-size: 12px;
    padding: 0 10rem;

    .head-left {
      margin-left: 10px;
      color: #000000;
      font-size: 16px;

      .left {
        padding-right: 5px;
        cursor: pointer;
      }

      .left-frite {
        color: #eedf11;
      }
    }

    .head-right {
      margin-right: 8px;
      display: flex;

      :deep(.el-input__wrapper) {
        background-color: #607e83;
        box-shadow: none;
      }

      span {
        font-size: 20px;
        cursor: pointer;
        margin-left: 8px;
        margin-right: 15px;
        padding-right: 8px;
        border-right: 1px solid rgba(251, 249, 249, 0.1);
      }
    }
  }

  .content {
    min-height: 600px;

    .have-List {
      // margin-top:10px;
      // padding: 30px;
      padding: 0 10rem;

      .list-content {
        margin: 15px;
        display: flex;
        flex-wrap: wrap;
      }

      .list-word {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        li {
          width: calc(20% - 10px);
          margin-right: 10px;
          list-style: none;
          margin-bottom: 16px;
          border-radius: 5px;
          overflow: auto;
          cursor: pointer;

          .word-img {
            position: relative;
            height: 146px;

            img {
              width: 100%;
              height: 100%;
              max-height: 146px;
              display: block;
              object-fit: cover;
            }
          }

          .word-icon {
            position: absolute;
            top: 10px;
            right: 8px;
            cursor: pointer;

            img {
              width: 20px;
              height: 20px;
              display: block;
            }
          }

          .word-peple {
            position: absolute;
            color: #ffffff;
            background-color: #0fd6cc;
            top: 10px;
            left: 8px;
            font-size: 12px;
            padding: 3px 6px;
            border-radius: 6px;
          }

          .word-category {
            height: 70px;
            font-size: 12px;
            background: #ffffff;

            .word-message {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 4px;
              margin-right: 4px;

              .message-right {
                margin: 5px;
                display: flex;
                align-items: center;
                width: 50%;

                img {
                  width: 30px;
                  height: 30px;
                  border-radius: 30px;
                  margin-right: 10px;
                }

                .message-name {
                  cursor: pointer;
                  font-size: 16px;
                  width: 80%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }
              }

              .no-icon {
                margin-right: 6px;
                margin-top: 5px;
                width: 37%;

                p {
                  cursor: pointer;
                  text-align: center;
                  padding: 8px 2px;
                  background-color: #0477a5;
                  border-radius: 6px;
                  color: #ffffff;
                  font-size: 16px;
                }
              }

              .message-icon {
                display: flex;
                margin-top: 3px;

                p {
                  margin: 4px 0px;
                  background-color: rgba(162, 219, 236, 0.5);
                  border-radius: 18px;
                  padding: 4px 6px;
                  margin-right: 6px;
                }

                .icon1 {
                  font-size: 16px;
                }
              }
            }

            .small-type {
              line-height: 20px;
              height: 20px;
              display: flex;
              flex-wrap: wrap;
              // overflow: hidden;
              overflow-y: hidden;
              border-radius: 10px;
              cursor: pointer;

              p {
                background-color: rgba(162, 219, 236, 0.5);
                margin-left: 7px;
                display: block;
                padding: 0px 6px;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }

    .noList {
      text-align: center;
      padding-top: 100px;
      margin: auto;

      h3 {
        line-height: 30px;
        color: #ffff;
      }

      p {
        margin-top: 15px;
      }

      span {
        padding: 7px;
        background-color: #f1f507;
        font-size: 12px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }
}

.show-more {
  color: #ffe400 !important;
  display: block;
  padding-top: 8px;
  margin: 0 auto;
  clear: both;
  overflow: hidden;
  text-align: center;
  font-size: 12px;
  line-height: 10px;
  cursor: pointer;
  text-transform: capitalize;
  font-family: Poppins;
}

.show-more:before {
  right: 16px;
  margin-left: -50%;
}

.show-more:after {
  left: 16px;
  margin-right: -50%;
}

.show-more:before,
.show-more:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 50%;
  height: 1px;
  vertical-align: middle;
  background-color: #ffffff2a;
}
</style>
